import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const TwoColumnComponent = ({ icon: Icon, heading, paragraph }) => {
  return (
    <Box p={2} className='w-11/12 sm:w-3/12 border border-2 border-[#F55327] rounded-lg m-4'>
      <Grid container spacing={2}>
        <Grid item xs={2}  className='flex items-center' >
          <Icon fontSize="large" sx={{ color: '#F55327!important'}}/>
        </Grid>
        <Grid item xs={10}>
          <Box>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold'}}>
              {heading}
            </Typography>
            <Typography variant="body1">
              {paragraph}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TwoColumnComponent;

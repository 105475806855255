import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const IconText = ({ icon: Icon, text, textColor, iconColor, bgHere, backgroundColor }) => {
    return (
        <Box
            className='m-4 w-[80%] sm:w-[20%] rounded-lg'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                backgroundColor: backgroundColor || '#ffffff',
                padding: '10px 20px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
        >
            <div className={`${bgHere} p-2 rounded-full m-2`}>
                <Icon
                    sx={{
                        fontSize: '48px',
                        color: iconColor || '#000000',
                    }}
                />
            </div>
            <div className='w-full text-start'>
                <Typography
                className='w-11/12'
                    variant="h6"
                    sx={{
                        marginTop: '10px',
                        color: textColor || '#000000',
                        textAlign: 'start',
                        fontWeight: 'bold'
                    }}
                >
                    {text}
                </Typography>
            </div>
        </Box>
    );
};

export default IconText;

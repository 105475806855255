import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Contact = () => {
    return (
        <>
            <div className='h-[64px]'></div>
            <section className='m-2 sm:m-8 border-2 border-[#948d8d] rounded-lg'>
                <div className='flex flex-col sm:flex-row'>
                    <div className='w-full sm:w-1/2 p-4'>
                        <div className='flex justify-start w-full'>
                            <h1 className='orangeBar uppercase ' style={{ fontWeight: 'bolder', margin: 0 }}>Contact Us</h1>
                        </div>
                        <div className='my-1'>
                            <p className="text-[#939393]">
                                Feel free to reach out to us for any software development needs or inquiries.
                            </p>
                        </div>
                        <form className="py-2 ">
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div>
                                    <div className="mb-1">
                                        <input
                                            type="text"
                                            name="name"
                                            required
                                            className="w-full px-3 py-2 border-2 border-[#948d8d] bg-backgroundPrimary rounded-md focus:outline-none focus:border-backgroundSecondary"
                                            placeholder="Name *"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1">
                                        <input
                                            type="text"
                                            name="phone"
                                            required
                                            className="w-full px-3 py-2 border-2 border-[#948d8d] bg-backgroundPrimary rounded-md focus:outline-none focus:border-backgroundSecondary"
                                            placeholder="Phone *"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="mb-1">
                                        <input
                                            type="email"
                                            name="email"
                                            required
                                            className="w-full px-3 py-2 border-2 border-[#948d8d] bg-backgroundPrimary rounded-md focus:outline-none focus:border-backgroundSecondary"
                                            placeholder="Email *"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="mb-1">
                                        <input
                                            type="text"
                                            name="location"
                                            className="w-full px-3 py-2 border-2 border-[#948d8d] bg-backgroundPrimary rounded-md focus:outline-none focus:border-backgroundSecondary"
                                            placeholder="Location"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="mb-1">
                                        <textarea
                                            name="message"
                                            className="w-full px-3 py-2 border-2 border-[#948d8d] bg-backgroundPrimary rounded-md focus:outline-none focus:border-backgroundSecondary"
                                            placeholder="Message"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="mb-1">
                                        <div className="g-recaptcha" data-sitekey="6LdU9z8mAAAAAAOHUvzFJQz-0hPqKrBlXUAuQiO_"></div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="mb-1">
                                        <input
                                            type="submit"
                                            className="cursor-pointer bg-backgroundSecondary hover:bg-backgroundSecondaryDark text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline"
                                            value="Submit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='sm:ml-10 w-full sm:w-1/2 border-t-2 sm:border-t-0 sm:border-l-2 border-[#948d8d]'>
                        <div>
                            <div className='flex flex-row w-full justify-between p-4'>
                                <div className='text-white flex flex-row items-center w-1/2'><CallIcon className='mr-2' /> +971 52 4676635</div>
                                <div className='flex flex-row items-center w-1/2 justify-end'>
                                    <a href="mailto:admin@aisd.io"><MailOutlineIcon className='mr-2' />admin@aisd.io</a>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between p-4 border-t-2 border-[#948d8d]'>
                                <LocationOnOutlinedIcon /> <p className='text-sm w-11/12'>
                                    Dubai Digital Park, 57 street, Dubai silicon oasis,
                                    Dubai, United Arab Emirates.
                                    AISD FZCO, License Number 17840
                                </p>
                            </div>
                            <div class="g-map-outer border-2 border-l-0 border-[#948d8d]">
                                <iframe
                                    title='g-map'
                                    src="https://www.google.com/maps/d/u/0/embed?mid=1WTibfMiINzmLj5wd3PBMJ_nKQL0BMzI&ehbc=2E312F"
                                    width="100%" height="500"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;

import React from 'react';

const About = () => {
  return (
    <>
      <div className='h-[64px]'></div>
      <section className='firstSection px-4 sm:px-10 py-10 sm:py-32 w-full'>
        <div className='bg-backgroundPrimary px-6 sm:px-14 py-4 sm:py-8 w-full sm:w-5/12'>
          <div className='flex items-center justify-center'>
            <h1 className='orangeBar' style={{ fontWeight: 'bolder' }}>About Us</h1>
          </div>
          <p className='text-xs sm:text-base text-textSecondary text-center'>We are AISD, a company with a team of multidisciplinary experts, we specialize in creating custom software solutions,
            our team works closely with the client to understand their requirements and develop a custom solution.</p>
        </div>
      </section>
      <section className='w-full flex flex-col sm:flex-row py-10 px-2'>
        <div className='w-full sm:w-1/2'>
          <h1 className='orangeBar' style={{ margin: '0' }}>Together We</h1>
          <h1 className='noBar' style={{ fontWeight: 'bolder', margin: '0', }}>Are Strong</h1>
        </div>
        <div className='w-full sm:w-1/2 mt-6 sm:mt-0'>
          <div className=''>
            <p className='text-sm sm:text-lg pb-4'>
              <i>In our software development company, we strongly believe in the power of working together and building a strong team.
                We recognize that success is not achieved alone but through collaboration and mutual support.
                Here, we focus on cultivating an inclusive and stimulating environment where every team member feels valued.</i>
            </p>
            <p className='text-sm sm:text-lg text-textSecondary pt-2 pb-6'>
              Together, we overcome challenges and celebrate achievements. Join our community of passionate professionals,
              from our talented engineers to our dedicated designers and quality experts,
              everyone contributes their unique skills and diverse perspectives to deliver innovative and high-quality software solutions.
              We'll elevate your ideas!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

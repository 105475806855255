import React from 'react';

const SplitComponent = ({ image, heading, text, link, linkText, styles, boxStyles, insideBoxStyles }) => {
  return (
    <div className={`flex ${styles}`}>
      <div className="md:w-1/2 w-full">
        <img src={image} alt={heading} className="object-cover w-full h-full" />
      </div>
      <div className={`md:w-1/2 w-full flex flex-col justify-center bg-backgroundPrimary ${boxStyles}`}>
        <div className={`${insideBoxStyles}`}>
          <h1 className="orangeBar">{heading}</h1>
          <p className="mb-10 text-textSecondary w-10/12">{text}</p>
          {link && linkText && (
            <a
              href={link}
              className="bg-backgroundSecondary hover:bg-backgroundSecondaryDark hover:scale-[1.008] text-white p-4 text-2xl mt-2 w-1/4 text-center"
            >
              {linkText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SplitComponent;

import React from 'react';

const ProjectCard = ({ imageSrc, projectName, projectDescription, websiteName, websiteUrl }) => {
  return (
    <div className="portfolio flex flex-col sm:flex-row p-5 border-2 border-backgroundSecondary rounded-lg shadow-lg m-5">
      <div className="flex-1 pr-5 w-full sm:w-1/2">
        <img src={imageSrc} alt="Project" className=" w-full h-auto rounded-lg shadow-md border-2 border-[#404040]" />
      </div>
      <div className="flex-2 flex flex-col w-full sm:w-1/2">
        <div className="mt-4 sm:mt-0 mb-2">
          <h2 className="text-2xl font-bold">{projectName}</h2>
        </div>
        <div className="mb-2">
          <p className="text-[#939393]">{projectDescription}</p>
        </div>
        <div className="mb-2">
          <p className="text-white">{websiteName}</p>
        </div>
        <div className="mb-2">
          <button
            className="px-4 py-2 bg-backgroundSecondary text-white hover:bg-backgroundSecondaryDark transition"
            onClick={() => window.open(websiteUrl, '_blank')}
          >
            Visit Website
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../assets/common/logo.svg';
import HostingImage from '../assets/common/server.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import TikTokIcon from '../assets/common/tik-tok.png';

const pages = ['Home', 'About Us', 'Our Services', 'Our Portfolio', 'Hosting'];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [activePage, setActivePage] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const currentPage = pages.find(page => pathname.includes(page.replace(/\s+/g, '').toLowerCase()));
    if (currentPage) {
      setActivePage(currentPage);
    } else {
      setActivePage('Home');
    }
  }, [location.pathname]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (page) => {
    setActivePage(page);
    handleCloseNavMenu();
    switch (page) {
      case 'Home':
        navigate('/');
        break;
      case 'About Us':
        navigate('/aboutus');
        break;
      case 'Our Services':
        navigate('/ourservices');
        break;
      case 'Our Portfolio':
        navigate('/ourportfolio');
        break;
      case 'Hosting':
        window.location.href = 'https://hosting.aisd.io/login';
        break;
      default:
        navigate(`/${page.replace(/\s+/g, '').toLowerCase()}`);
        break;
    }
  };


  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#2F292B' }} className='AppBarHeader'>
      <Container maxWidth="xl" className='ContainerHeader'>
        <Toolbar disableGutters className='flex flex-row mb-2'>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} className='w-4/12'>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleMenuItemClick(page)}>
                  <Typography textAlign="center"
                    className='flex flex-row'
                    sx={{
                      color: activePage === page ? 'white' : '#2F292B',
                      position: 'relative',
                      '::after': activePage === page ? {
                        content: '""',
                        width: '4px',
                        height: '17px',
                        background: '#2F292B',
                        position: 'absolute',
                        left: '-8px',
                        top: '4px',
                      } : {}
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <img className='mr-1 sm:mr-0 w-4/12 sm:w-[11%] sm:mt-2 mt-1 pt-1 cursor-pointer' src={Logo} alt='AISD Logo' onClick={() => navigate('/')} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='mx-14  sm:mt-2'>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleMenuItemClick(page)}
                sx={{
                  my: 2,
                  color: activePage === page ? 'white' : '#BDBDBD',
                  display: 'flex',
                  position: 'relative',
                  '::after': activePage === page ? {
                    content: '""',
                    width: '4px',
                    height: '17px',
                    background: '#F55937',
                    position: 'absolute',
                    left: '10px',
                    top: '14px',
                  } : {}
                }}
              >
                {page === 'Hosting' && (
                  <>
                    <img src={HostingImage} width="30" style={{ marginRight: '7px' }} alt="hosting" />
                    <span style={{ color: '#F55937', textTransform: 'uppercase' }}>{page}</span>
                  </>
                )}
                {page !== 'Hosting' && page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }} className='flex flex-row w-4/12 sm:justify-end justify-center mt-2'>
            <a href="https://www.instagram.com/aisd.io/" target="_blank" rel="noopener noreferrer" className='flex'>
              <InstagramIcon className='ml-1 sm:ml-4 EmailIcon' />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61561663643275" target="_blank" rel="noopener noreferrer" className='flex'>
              <FacebookIcon className='ml-1 sm:ml-4 EmailIcon' />
            </a>
            <a href="https://x.com/aisd_io" target="_blank" rel="noopener noreferrer" className='flex'>
              <XIcon className='ml-1 sm:ml-4 EmailIcon' />
            </a>
            <a className='flex items-center' href="https://www.tiktok.com/@aisd.io" target="_blank" rel="noopener noreferrer">
              <img src={TikTokIcon} className='ml-1 sm:mx-4 EmailIcon' alt="AISD Logo" />
            </a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;

import * as React from 'react';
import Logo from '../assets/common/logo.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import TikTokIcon from '../assets/common/tik-tok.png';

function Footer() {
    return (
        <>
            <div className='flex flex-col'>
                <div className='flex flex-col sm:flex-row justify-between items-center border-t-4 border-[#534A4D] mb-2 '>
                    <div className='flex justify-between sm:justify-center flex-row sm:flex-col mt-2 border-b border-[#534A4D] sm:border-none'>
                        <img className='p-2 pb-2 sm:pb-2 sm:p-2 w-5/12 sm:w-[98%]' src={Logo} alt='AISD Logo' />
                        <div sx={{ flexGrow: 0 }} className='flex flex-row items-center justify-around '>
                            <a href="https://www.instagram.com/aisd.io/" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon className='ml-2 EmailIcon' />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61561663643275" target="_blank" rel="noopener noreferrer">
                                <FacebookIcon className='ml-2 EmailIcon' />
                            </a>
                            <a href="https://x.com/aisd_io" target="_blank" rel="noopener noreferrer">
                                <XIcon className='ml-2 EmailIcon' />
                            </a>
                            <a className='flex items-center' href="https://www.tiktok.com/@aisd.io" target="_blank" rel="noopener noreferrer">
                                <img src={TikTokIcon} className='mx-2 EmailIcon'  alt="AISD Logo" />
                            </a>
                        </div>
                    </div>
                    <div className='w-full sm:w-1/2 mt-2 sm:mt-0'>
                        <ul className='flex flex-row justify-around cursor-pointer'>
                            <li className='mr-2 sm:mr-0 text-xs sm:text-base'><a href='/'>Home</a></li>
                            <li className='mr-2 sm:mr-0 text-xs sm:text-base'><a href='/aboutus'>About Us</a></li>
                            <li className='mr-2 sm:mr-0 text-xs sm:text-base'><a href='/ourservices'>Our Services</a></li>
                            <li className='mr-2 sm:mr-0 text-xs sm:text-base'><a href='/ourportfolio'>Our Portfolio</a></li>
                        </ul>
                    </div>
                </div>
                <div className='border-t border-[#534A4D] flex-col sm:flex-row flex items-center justify-between'>
                    <p className='text-textSecondary text-xs p-4 w-full text-center sm:text-start sm:w-1/3'>Dubai Digital Park, 57 street, Dubai silicon oasis,<br />
                        Dubai, United Arab Emirates. <br />
                        AISD FZCO, License Number 17840</p>
                    <p className='text-textSecondary text-sm p-4'>
                        Copyright © 2023 aisd.io . All Rights Reserved.
                    </p>
                </div>
            </div>
        </>
    );
}


export default Footer;
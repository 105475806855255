import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Portfolio from './Pages/Portfolio';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './Pages/Contact';
import CookieConsent from './components/CookieConsent';

const App = () => {
  return (
    <>
    <CookieConsent />
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/ourservices" element={<Services />} />
        <Route path="/ourportfolio" element={<Portfolio />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
};

export default App;

import Logo from '../assets/common/logo-primary-icon.svg';
import ProjectCard from '../components/ProjectCard';
import Nsur from '../assets/home/clients/nsur.jpg';
import Snap from '../assets/home/clients/snap.jpg';
import PREMIERX4FREE from '../assets/home/clients/premierxfree.jpg';
import NUMBERGAME from '../assets/home/clients/plays-number-game.jpg';

const projects = [
    {
      imageSrc: Nsur,
      projectName: 'NSUR',
      projectDescription: 'NSUR is a cryptocurrency and blockchain-based platform that allows users to store, transfer, and trade digital assets securely and easily.',
      websiteName: 'www.nsurcoin.com',
      websiteUrl: 'https://www.nsurcoin.com',
    },
    {
      imageSrc: Snap,
      projectName: 'SNAP DELIVERED',
      projectDescription: 'SNAP Delivered is a food delivery service that connects customers with local restaurants and allows them to order food online for delivery or pickup.',
      websiteName: 'www.snapdeliveredteam.com',
      websiteUrl: 'https://www.snappartners.com/',
    },
    {
      imageSrc: PREMIERX4FREE,
      projectName: 'PREMIERX4FREE',
      projectDescription: 'Premierx4Free is a loyalty program that offers discounts and rewards to customers who make purchases from participating merchants.',
      websiteName: 'www.premierx4free.com',
      websiteUrl: 'https://www.premierx4free.com/',
    },
    {
      imageSrc: NUMBERGAME,
      projectName: 'PLAYS NUMBER GAME',
      projectDescription: 'Plays Number Game is an online platform for playing lottery-style games, with various games and jackpots available for users to play and win.',
      websiteName: 'www.playnumbersgame.com',
      websiteUrl: 'https://www.playnumbersgame.com',
    },
  ];

const Portfolio = () => {
    return (
        <>
            <div className='h-[80px]'></div>
            <div className='m-2 sm:m-10 flex flex-col sm:flex-row'>
                <div className='w-full sm:w-1/2'>
                    <div>
                        <h1 className='bigTxt text-white flex justify-center sm:justify-start'> A GOOD <span className='primary-bg'>PORTFOLIO</span></h1>
                    </div>
                    <div className='flex justify-center sm:justify-start'>
                        <h3 className='uppercase text-small '><span className='text-textSecondary'>IS MORE THAN</span> A LONG LIST OF </h3>
                    </div>
                    <div>
                        <h3 className='primary-text-org flex justify-center sm:justify-start'>GOOD STOCKS AND BONDS</h3>
                    </div>
                </div>
                <div className='w-full sm:w-1/2'>
                    <div className='flex flex-row items-center'>
                        <div className='w-1/2 ani-img-01 m-1'>
                        </div>
                        <div className='flex flex-col w-1/2'>
                            <div className='w-full ani-img-02 m-1 portfolio-two border-b-8 border-backgroundSecondary'>
                            </div>
                            <div className='border-b-8 border-[#534A4D] bg-[#534A4D] m-1 h-4 w-full'></div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className='w-3/12 flex justify-center'>
                            <img className='p-2' src={Logo} alt='AISD Logo' />
                        </div>
                        <div className='w-9/12'>
                            <div className='w-full ani-img-03 m-1 portfolio-two border-b-8 border-backgroundSecondary'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='border-t border-[#595959] py-10'>
                <div className='flex justify-center w-full'>
                    <h1 className='orangeBar uppercase ' style={{ fontWeight: 'bolder' }}>Our Portfolio</h1>
                </div>
                <div className='text-center w-full flex justify-center'>
                    <p className='px-2 sm:px-10 w-full sm:w-8/12 text-center'>Explore our diverse portfolio of projects, showcasing our expertise in creating exceptional mobile applications
                        and robust web solutions. See how our team's creativity and technical prowess have made a significant impact
                        across various industries.</p>
                </div>
                <div className="p-4 sm:p-10 grid grid-cols-1 md:grid-cols-2 gap-0 sm:gap-2">
                    {projects.map((project, index) => (
                        <ProjectCard key={index} {...project} />
                    ))}
                </div>
            </section>
        </>
    );
};

export default Portfolio;

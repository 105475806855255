import React from 'react';
import IconText from '../components/IconText';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SplitComponent from '../components/SplitComponent';
import webDev from '../assets/home/services/web-img.png';
import appDev from '../assets/home/services/app-img.png';
import UxDev from '../assets/home/services/uxui.png';

const Services = () => {
    return (
        <>
            <div className='h-[64px]'></div>
            <div className='mt-2 sm:mt-16 mb-6 mx-4'>
                <h1 className='orangeBar' style={{ fontWeight: 'bolder' }}>Our Services</h1>
            </div>
            <div className='flex flex-col items-center'>
                <div className='flex justify-center w-full'>
                    <h1 className='orangeBar'>Hosting Servers</h1>
                </div>
                <div className='flex flex-col sm:flex-row justify-center items-center w-full'>
                    <IconText
                        icon={GppGoodOutlinedIcon}
                        bgHere="bg-black"
                        text="Advanced Security"
                        textColor="black"
                        iconColor="white"
                        backgroundColor="white"
                    />
                    <IconText
                        icon={ThunderstormIcon}
                        bgHere="bg-white"
                        text="99.99% Uptime"
                        textColor="white"
                        iconColor="black"
                        backgroundColor="black"
                    />
                    <IconText
                        icon={HeadsetMicIcon}
                        bgHere="bg-black"
                        text="24/7 Support"
                        textColor="black"
                        iconColor="white"
                        backgroundColor="white"
                    />
                </div>
            </div>
            <div className='my-16'>
                <SplitComponent
                    image={webDev}
                    heading="Web Development"
                    text="We transform your ideas into stunning and functional websites. Our experienced team of developers utilizes the latest technologies and industry best practices to build responsive, scalable, and secure web solutions. Whether you need a simple brochure website or a complex e-commerce platform, we deliver tailored solutions that meet your business objectives. Trust us to bring your web presence to life with our expertise in web development."
                    styles="flex-col md:flex-row"
                    boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
                    insideBoxStyles="ml-4 sm:ml-20"
                />
                <SplitComponent
                    image={appDev}
                    heading="App Development"
                    text="Explore the world of possibilities with our app development services. Our skilled team of developers specializes in creating high-performance and feature-rich mobile applications for iOS and Android platforms. From concept to deployment, we ensure a seamless development process, leveraging cutting-edge technologies and user-centric design principles. Partner with us to turn your app ideas into reality and engage your target audience on the go."
                    styles="flex-col md:flex-row-reverse"
                    boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
                    insideBoxStyles="ml-4 sm:ml-20"
                />
                <SplitComponent
                    image={UxDev}
                    heading="UI/UX DESIGN"
                    text="At our software development company, we specialize in UI design that combines aesthetics with user-centric functionality. Our talented team of designers crafts visually stunning interfaces that enhance user experiences and elevate your software to new heights. Trust us to create intuitive and engaging user interfaces that leave a lasting impression."
                    styles="flex-col md:flex-row"
                    boxStyles="pt-2 pb-8 sm:py-8 sm:pt-8"
                    insideBoxStyles="ml-4 sm:ml-20"
                />
            </div>
        </>
    );
};

export default Services;

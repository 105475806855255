import React, { useEffect, useState } from "react";
import Logo from '../assets/common/logo-primary-icon.svg';
import CloseIcon from '@mui/icons-material/Close';

const CookieConsent = () => {
    const [consent, setConsent] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        const storedConsent = localStorage.getItem("cookie-consent");
        setConsent(storedConsent);

        if (storedConsent === "true") {
            enableGoogleAnalytics();
        } else if (storedConsent === "false") {
            disableGoogleAnalytics();
        }
    }, []);

    const enableGoogleAnalytics = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-1713HDKB7L', { 'anonymize_ip': true });

        const gtmScript = document.createElement('script');
        gtmScript.async = true;
        gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-M2F5DH8';
        document.head.appendChild(gtmScript);

        const gtmNoScript = document.createElement('noscript');
        const gtmIframe = document.createElement('iframe');
        gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-M2F5DH8';
        gtmIframe.height = "0";
        gtmIframe.width = "0";
        gtmIframe.style.display = "none";
        gtmIframe.style.visibility = "hidden";
        gtmNoScript.appendChild(gtmIframe);
        document.body.appendChild(gtmNoScript);
    };

    const disableGoogleAnalytics = () => {
        window["ga-disable-G-1713HDKB7L"] = true;
        window["ga-disable-GTM-M2F5DH8"] = true;
    };

    useEffect(() => {
        const initializeGoogleAnalytics = () => {
            if (consent === "true") {
                enableGoogleAnalytics();
            } else {
                disableGoogleAnalytics();
            }
        };

        window.addEventListener("load", initializeGoogleAnalytics);

        return () => {
            window.removeEventListener("load", initializeGoogleAnalytics);
        };
    }, [consent]);

    const handleCookieConsent = (consent) => {
        localStorage.setItem("cookie-consent", consent ? "true" : "false");
        setConsent(consent ? "true" : "false");

        const cookieBanner = document.getElementById("overlay-1");
        cookieBanner.classList.add("fade-out");
    };

    const showCookieSettings = () => {
        setShowSettings(true);
    };

    const saveCookieSettings = () => {
        const analysisCookies = document.getElementById('analysis-cookies').checked;
        if (analysisCookies) {
            localStorage.setItem("cookie-consent", "true");
            setConsent("true");
            enableGoogleAnalytics();
        } else {
            localStorage.setItem("cookie-consent", "false");
            setConsent("false");
            disableGoogleAnalytics();
        }
        closePopup();
    };

    const closePopup = () => {
        const cookieBanner = document.getElementById("overlay-1");
        cookieBanner.classList.add("fade-out");
    };

    const handleAcceptAll = () => {
        document.getElementById('analysis-cookies').checked = true;
        document.getElementById('preference-cookies').checked = true;
        document.getElementById('advertising-cookies').checked = true;
        handleCookieConsent(true);
    };

    const handleClosePopup = () => {
        localStorage.removeItem("cookie-consent");
        closePopup();
    };

    if (consent !== null) {
        return null;
    }
    return (
        <div id="overlay-1">
            <div id="cookie-consent" style={{ display: showSettings ? "none" : "block" }}>
                <div className="cookie-box">
                    <div className="w-full flex justify-end">
                        <CloseIcon className="text-white cursor-pointer" onClick={handleClosePopup}/>
                    </div>
                    <img src={Logo} alt="AISD Logo" className="w-2/12" />
                    <h2 style={{ color: "white", margin: "2% 0%" }}>THIS WEBSITE USES COOKIES</h2>
                    <p>We use cookies to enhance your experience, analyze site traffic, and personalize content.
                        By clicking "Accept All", you consent to our use of cookies. You can manage your preferences by
                        clicking "Settings"</p>
                </div>
                <div className="btn-contains">
                    <button backgroundColor="#F55937" className="primary-btt op-color" onClick={() => handleCookieConsent(true)}>Accept All</button >
                    <button borderRadius="10px" className="primary-btt op-color op-decline" onClick={() => handleCookieConsent(false)}>Reject All</button >
                    <span onClick={showCookieSettings} style={{ cursor: "pointer", marginTop: "1%" }}>
                        <p>Cookies Settings</p>
                    </span>
                </div>
            </div>
            {showSettings && (
                <div id="cookie-settings" className="hidden">
                    <div class="cookie-box">
                        <div class="img-logo">
                            <img src={Logo} width="60" alt="AISD Logo" />
                        </div>
                        <div class="pos-rel">
                            <h2 className='text-white mx-2 mt-4 mb-2 text-center'>Cookie Preference Settings </h2>
                            <div class="orange-no-btn">
                                <p>Technical and Required Cookies</p>
                            </div>
                            <div class="toggle-container grey-no-btn">
                                <p>Analysis and Measurement Cookies</p>
                                <label class="toggle-switch">
                                    <input type="checkbox" id="analysis-cookies" />
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div class="toggle-container grey-no-btn">
                                <p>Preference or Customization Cookies</p>
                                <label class="toggle-switch">
                                    <input type="checkbox" id="preference-cookies" />
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div class="toggle-container grey-no-btn">
                                <p>Behavioral Advertising Cookies</p>
                                <label class="toggle-switch">
                                    <input type="checkbox" id="advertising-cookies" />
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <p style={{ margin: '1% 0', color: 'white' }}>Technical and Required Cookies are necessary for our
                                website to function correctly. They ensure basic functionalities and security features of
                                the
                                website. These cookies cannot be disabled.</p>
                            <div class="btn-contains">
                                <button onClick={handleAcceptAll} id="accept-all" class="primary-btt op-color">Accept
                                    All</button>
                                <button id="decline-cookie" class="primary-btt op-color op-decline"
                                    onClick={() => handleCookieConsent(false)}>Reject All</button>
                                <span id="save-settings" className='pt-2 text-center cursor-pointer'>
                                    <span className="text-white cursor-pointer" onClick={saveCookieSettings}>Save Settings</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CookieConsent;
